/* ImageCarousel.css */

.slide-left-enter {
  opacity: 0;
  transform: translateX(100%);
}
.slide-left-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: transform 500ms ease-in-out, opacity 500ms ease-in-out;
}
.slide-left-exit {
  opacity: 1;
  transform: translateX(0%);
}
.slide-left-exit-active {
  opacity: 0;
  transform: translateX(-100%);
  transition: transform 500ms ease-in-out, opacity 500ms ease-in-out;
}

.slide-right-enter {
  opacity: 0;
  transform: translateX(-100%);
}
.slide-right-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: transform 500ms ease-in-out, opacity 500ms ease-in-out;
}
.slide-right-exit {
  opacity: 1;
  transform: translateX(0%);
}
.slide-right-exit-active {
  opacity: 0;
  transform: translateX(100%);
  transition: transform 500ms ease-in-out, opacity 500ms ease-in-out;
}
